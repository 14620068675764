<template>
  <v-row>
    <v-col cols="auto">
      <!-- Points Size -->
      <StyleItem
        class="style-item"
        label="Points Size"
      >
        <v-text-field
          v-model="properties.custom.pointSize"
          class="mt-0 pt-0"
          hide-details="auto"
          single-line
          type="number"
          :min="limits.custom.pointSize.min"
          :max="limits.custom.pointSize.max"
          dense
          style="width: 60px;"
          @blur="checkMinMax('custom.pointSize')"
        />
      </StyleItem>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import StyleItem from './StyleItem.vue'

import dot from 'dot-object'

const limits = {
  custom: {
    pointSize: {
      min: 1,
      max: 40
    }
  }
}

export default {
  name: 'PointsStyleEditor',

  components: {
    StyleItem
  },

  props: {
    properties: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    limits
  }),

  methods: {
    checkMinMax(id) {
      const { min, max } = dot.pick(id, limits)
      const property = parseFloat(dot.pick(id, this.properties))

      if (Number.isNaN(property)) {
        dot.str(id, null, this.properties)

        return
      }

      if (property < min) dot.str(id, min, this.properties)
      if (property > max) dot.str(id, max, this.properties)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

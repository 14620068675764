<template>
  <v-row>
    <v-col>
      <!-- Bar Alignment -->
      <StyleItem
        class="style-item"
        label="Bar Alignment"
      >
        <v-btn-toggle
          v-model="properties.custom.barAlignment"
          dense
          mandatory
        >
          <v-btn
            :value="-1"
            small
          >
            Before
          </v-btn>
          <v-btn
            :value="0"
            small
          >
            Center
          </v-btn>
          <v-btn
            :value="1"
            small
          >
            After
          </v-btn>
        </v-btn-toggle>
      </StyleItem>
      <!-- Line Style -->
      <StyleItem
        class="style-item"
        label="Line Style"
      >
        <v-btn-toggle
          v-model="lineStyle"
          dense
          mandatory
        >
          <v-btn
            value="solid"
            small
          >
            Solid
          </v-btn>
          <v-btn
            value="dash"
            small
          >
            Dash
          </v-btn>
          <v-btn
            value="dot"
            small
          >
            Dots
          </v-btn>
        </v-btn-toggle>
      </StyleItem>
      <!-- Line Width -->
      <StyleItem
        class="style-item"
        label="Line Width"
      >
        <v-text-field
          v-model="properties.custom.lineWidth"
          class="mt-0 pt-0"
          hide-details="auto"
          single-line
          type="number"
          :min="limits.custom.lineWidth.min"
          :max="limits.custom.lineWidth.max"
          dense
          style="width: 60px;"
          @blur="checkMinMax('custom.lineWidth')"
        />
      </StyleItem>
    </v-col>
    <v-col>
      <!-- Gradient Mode -->
      <StyleItem
        class="style-item"
        label="Gradient Mode"
      >
        <v-btn-toggle
          v-model="properties.custom.gradientMode"
          dense
          mandatory
        >
          <v-btn
            value="none"
            small
          >
            None
          </v-btn>
          <v-btn
            value="opacity"
            small
          >
            Opacity
          </v-btn>
          <v-btn
            value="hue"
            small
          >
            Hue
          </v-btn>
          <v-btn
            value="scheme"
            small
          >
            Scheme
          </v-btn>
        </v-btn-toggle>
      </StyleItem>
      <!-- Fill Opacity -->
      <StyleItem
        class="style-item"
        label="Fill Opacity"
      >
        <v-text-field
          v-model="properties.custom.fillOpacity"
          class="mt-0 pt-0"
          hide-details="auto"
          single-line
          type="number"
          :min="limits.custom.fillOpacity.min"
          :max="limits.custom.fillOpacity.max"
          :step="1"
          dense
          style="width: 60px;"
          @blur="checkMinMax('custom.fillOpacity')"
        />
      </StyleItem>
    </v-col>
    <v-col>
      <!-- Show Points -->
      <StyleItem
        class="style-item"
        label="Show Points"
      >
        <v-btn-toggle
          v-model="properties.custom.showPoints"
          dense
          mandatory
        >
          <v-btn
            value="auto"
            small
          >
            Auto
          </v-btn>
          <v-btn
            value="always"
            small
          >
            Always
          </v-btn>
          <v-btn
            value="never"
            small
          >
            Never
          </v-btn>
        </v-btn-toggle>
      </StyleItem>
      <!-- Points Size -->
      <StyleItem
        v-if="properties.custom.showPoints !== 'never'"
        class="style-item"
        label="Points Size"
      >
        <v-text-field
          v-model="properties.custom.pointSize"
          class="mt-0 pt-0"
          hide-details="auto"
          single-line
          type="number"
          :min="limits.custom.pointSize.min"
          :max="limits.custom.pointSize.max"
          dense
          style="width: 60px;"
          @blur="checkMinMax('custom.pointSize')"
        />
      </StyleItem>
    </v-col>
    <v-spacer />
  </v-row>
</template>

<script>
import StyleItem from './StyleItem.vue'

import dot from 'dot-object'

const limits = {
  custom: {
    fillOpacity: {
      min: 0,
      max: 100
    },
    lineWidth: {
      min: 0,
      max: 10
    },
    pointSize: {
      min: 1,
      max: 40
    }
  }
}

export default {
  name: 'BarsStyleEditor',

  components: {
    StyleItem
  },

  props: {
    properties: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    limits
  }),

  computed: {
    lineStyle: {
      get() {
        return this.properties.custom.lineStyle.fill
      },
      set(newVal) {
        this.properties.custom.lineStyle.fill = newVal

        switch (newVal) {
          case 'solid':
            delete this.properties.custom.lineStyle.dash
            break
          case 'dash':
            this.properties.custom.lineStyle.dash = [10, 10]
            break
          case 'dot':
            this.properties.custom.lineStyle.dash = [0, 10]
            break
        }
      }
    }
  },

  methods: {
    checkMinMax(id) {
      const { min, max } = dot.pick(id, limits)
      const property = parseFloat(dot.pick(id, this.properties))

      if (Number.isNaN(property)) {
        dot.str(id, null, this.properties)

        return
      }

      if (property < min) dot.str(id, min, this.properties)
      if (property > max) dot.str(id, max, this.properties)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <v-row no-gutters>
    <v-col
      class="label d-flex align-center"
      cols="12"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </v-col>
    <v-col
      class="form-control d-flex align-center"
      cols="12"
    >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'StyleItem',

  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.label,
.form-control {
  padding: 0 12px;
  height: 28px;
  font-size: 0.875rem;
}

.label {
  font-size: 0.75rem;
}
</style>

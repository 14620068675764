<template>
  <div
    class="json-editor"
    style="height: 100%;"
  >
    <textarea ref="codemirror" />
  </div>
</template>

<script>
import CodeMirror from 'codemirror'

import beautify from 'js-beautify'

// import libs
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/selection/active-line'

import 'codemirror/addon/edit/matchbrackets'

import 'codemirror/addon/display/fullscreen'
import 'codemirror/addon/display/autorefresh'
import 'codemirror/addon/scroll/simplescrollbars'

// import '@/plugins/jsonlint'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/json-lint'

// import styles
import 'codemirror/lib/codemirror.css'

import 'codemirror/theme/dracula.css'

import 'codemirror/addon/lint/lint.css'

import 'codemirror/addon/display/fullscreen.css'
import 'codemirror/addon/scroll/simplescrollbars.css'

const theme = 'dracula'

const defaultCmOptions = {
  tabSize: 4,
  styleActiveLine: true,
  lineNumbers: true,
  lineWrapping: false,
  line: true,
  foldGutter: false,
  styleSelectedText: true,
  mode: 'application/json',
  lint: false,
  gutters: ['CodeMirror-lint-markers'],
  fixedGutter: true,
  scrollbarStyle: 'simple',
  matchBrackets: true,
  viewportMargin: Infinity,
  extraKeys: {
    F11: cm => {
      cm.setOption('fullScreen', !cm.getOption('fullScreen'))
    },
    Esc: cm => {
      if (cm.getOption('fullScreen')) cm.setOption('fullScreen', false)
    }
  },
  theme
}

const defaultBeautfiyOptions = {
  indent_size: 4,
  brace_style: 'expand'
}

export default {
  name: 'CodeMirror',

  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Object,
      default: null
    },
    tabSize: {
      type: Number,
      default: 2
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    codemirror: null,
    isLoadingTheme: false,
    theme,
    themeOpts: [
      {
        label: 'light',
        value: 'default'
      },
      {
        label: 'dark',
        value: 'material-darker'
      },
      {
        label: 'eclipse',
        value: 'eclipse'
      },
      {
        label: 'elegant',
        value: 'elegant'
      },
      {
        label: 'mbo',
        value: 'mbo'
      },
      {
        label: 'blackboard',
        value: 'blackboard'
      }
    ]
  }),

  computed: {
    cmOptions() {
      return {
        // default opts
        ...defaultCmOptions,
        // props for opts
        theme: this.theme || theme,
        tabSize: this.tabSize,
        readOnly: this.readonly,
        // props opts
        ...(this.options || {})
      }
    },
    bfOptions() {
      return {
        ...defaultBeautfiyOptions,
        indent_size: this.tabSize
      }
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.handlerCodeChange(newVal)
      }
    }
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.destroy()
  },

  methods: {
    async init() {
      this.destroy()

      this.codemirror = CodeMirror.fromTextArea(
        this.$refs.codemirror,
        this.cmOptions
      )

      // if (this?.options?.theme) {
      //   await this.changeTheme(this.options.theme)
      // }

      this.codemirror.setValue(this.value)

      this.registEvents()

      this.formatCode()

      this.refresh()
    },
    formatCode() {
      let value = this.codemirror.getValue()

      value = beautify.js(value, this.bfOptions)

      this.codemirror.setValue(value)
    },
    setValue(value) {
      this.codemirror.setValue(value)
    },
    handlerCodeChange(newCode) {
      const currentCode = this?.codemirror?.getValue()

      if (newCode === currentCode) return

      this.setValue(newCode || '')
    },
    registEvents() {
      if (!this.codemirror) return

      this.codemirror.on('change', cm => {
        const code = cm.getValue()
        if (this.$emit) {
          this.$emit('input', code)
        }
      })
    },
    // changeTheme(theme, isBgLoading = false) {
    //   if (theme === 'default') {
    //     this.codemirror.setOption('theme', 'default')

    //     return Promise.resolve()
    //   }

    //   this.isLoadingTheme = !isBgLoading
    //   return import(`codemirror/theme/${theme}.css`)
    //     .then(() => {
    //       this.codemirror.setOption('theme', theme)
    //     })
    //     .finally(() => {
    //       setTimeout(() => {
    //         this.isLoadingTheme = false
    //       }, 200)
    //     })
    // },
    docAction(action) {
      const doc = this.codemirror.getDoc()

      if (doc && typeof doc[action] === 'function') {
        doc[action]()
      }
    },
    destroy() {
      // garbage cleanup
      return this.codemirror?.doc?.cm?.getWrapperElement?.()?.remove?.()
    },
    refresh() {
      this.$nextTick(() => {
        if (!this.codemirror) return

        this.codemirror.refresh()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.json-editor {
  ::v-deep .CodeMirror {
    height: 100%;
    font-size: 1rem;
  }
}
</style>

<style lang="scss">
.CodeMirror-lint-tooltip {
  // Must large than v-dialog z-index
  z-index: 10000;
}
</style>

<template>
  <div style="height: 100%;">
    <!-- panel viewer -->
    <v-row
      class="panel-frame px-6"
      :class="{ hide: !showPanel }"
      no-gutters
    >
      <!-- <v-col class="white d-flex justify-center mr-6" cols="auto">
        <v-btn icon @click="showPanel = !showPanel">
          <v-icon v-if="showPanel">mdi-eye</v-icon>
          <v-icon v-else>mdi-eye-off</v-icon>
        </v-btn>
      </v-col> -->
      <v-col class="white">
        <v-progress-linear
          v-if="isLoadingPreview"
          color="primary"
          indeterminate
        />
        <iframe
          v-else-if="panelSrc"
          class="panel-iframe"
          async
          :src="panelSrc"
          width="100%"
          height="100%"
          frameborder="0"
        />
        <v-row
          v-else
          class="text-h2 grey--text"
          no-gutters
          justify="center"
          align="center"
          style="height: 100%;"
        >
          <v-btn
            class="text-h5"
            color="primary"
            outlined
            x-large
            style=" width: 310px; height: 80px;border-style: dashed; border-width: revert;"
            @click="preview()"
          >
            {{ $t('preview_panel') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <!-- data actions -->
    <v-row
      class="px-12 py-3"
      no-gutters
    >
      <v-col
        v-visible="editModel===editOption.target"
        cols="auto"
      >
        <v-btn
          class="action-btn"
          color="secondary"
          @click="addTarget"
        >
          <v-icon size="16">
            mdi-plus-box
          </v-icon>
          <span class="ml-2">{{ $t('page.panels.add_target') }}</span>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        v-show="panelSrc"
        cols="auto"
      >
        <v-btn
          class="action-btn"
          color="secondary"
          :loading="isLoadingPreview"
          @click="preview()"
        >
          <v-icon size="16">
            mdi-refresh
          </v-icon>
          <span class="ml-2">{{ $t('preview') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <!-- data -->
    <v-row
      class="panel-data-editor"
      no-gutters
    >
      <!-- edit model list -->
      <v-col
        class="white d-flex justify-center mr-6"
        cols="auto"
      >
        <v-list
          class="edit-model-list"
          dense
        >
          <v-list-item-group
            v-model="editModel"
            color="primary"
            mandatory
          >
            <v-list-item :value="editOption.target">
              <v-list-item-icon>
                <v-icon>mdi-chart-multiline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <!-- 必須要有v-list-item-content才不會在點擊時出現寬度變化 -->
              </v-list-item-content>
            </v-list-item>
            <v-list-item :value="editOption.panelStyle">
              <v-list-item-icon>
                <v-icon>mdi-chart-areaspline-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <!-- 必須要有v-list-item-content才不會在點擊時出現寬度變化 -->
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>

      <!-- data settings -->
      <v-col style=" overflow-y: auto;height: 100%;">
        <!-- eslint-disable-next-line vue/html-self-closing -->
        <TargetEditor
          v-show="editModel===editOption.target"
          ref="targetEditor"
        ></TargetEditor>
        <PanelStyleEditor v-show="editModel===editOption.panelStyle" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TargetEditor from './TargetEditor/TargetEditor'
import PanelStyleEditor from './PanelStyleEditor/PanelStyleEditor'
import { getTimeRangeByRoute } from '@/components/TimeRangeSelect/TimeRangeSelect'

import { mapGetters } from 'vuex'

const qkFrom = process.env.VUE_APP_QUERY_KEY_TIME_FROM
const qkTo = process.env.VUE_APP_QUERY_KEY_TIME_TO

const editOption = {
  target: 'target',
  panelStyle: 'panelStyle'
}

export default {
  name: 'PanelAdderPreview',

  components: {
    TargetEditor,
    PanelStyleEditor
  },

  data: () => ({
    showPanel: true,
    editModel: 'target',
    isLoadingPreview: false,
    snapshotUrl: '',
    editOption
  }),

  computed: {
    ...mapGetters({
      getDashboardByRoute: 'dashboards/getDashboardByRoute',
      getNewPanel: 'panels/form/getNewPanel'
    }),

    timezone() {
      return this?.$route?.query?.timezone || 'utc'
    },
    time() {
      return getTimeRangeByRoute(this.$route)
    },
    timeRange() {
      const from = this.$route.query[qkFrom]
      const to = this.$route.query[qkTo]

      return [from, to]
    },
    projectId() {
      return this.$route?.params?.projectId
    },
    dashboard() {
      return this.getDashboardByRoute(this.$route) || {}
    },
    panelSrc() {
      const url = this.snapshotUrl

      if (!url) return

      return `${url}&theme=light`

      // // return `${url}?from=883612800000&to=1638403199000&orgId=1&panelId=2225&theme=light`
      // return `${url}?orgId=1&kiosk&fullscreen&theme=light`
    }
  },

  watch: {
    timezone(newVal, oldVal) {
      if ((!this.snapshotUrl && !this.isLoadingPreview) || newVal === oldVal) {
        return
      }

      this.preview()
    },
    timeRange: {
      handler(newVal, oldVal) {
        if (
          (!this.snapshotUrl && !this.isLoadingPreview) ||
          newVal.every((t, iT) => t === oldVal[iT])) {
          return
        }

        this.preview()
      },
      deep: true
    }
  },

  methods: {
    validate() {
      return this.$refs.targetEditor.validate()
    },
    resetValidation() {
      return this.$refs.targetEditor.resetValidation()
    },
    addTarget() {
      this.$store.dispatch('panels/form/addTarget')
    },
    async preview() {
      this.isLoadingPreview = true

      const { resourceId, ...dashboard } = this.dashboard
      const newPanel = this.getNewPanel(dashboard, this.projectId)
      const timezone = this.timezone
      const time = this.time
      this.snapshotUrl = await this.$store
        .dispatch('panels/fetchSnapshotUrl', {
          panel: newPanel,
          dashboard,
          time,
          dashboardOptions: {
            timezone
          }
        })
        .then(snapshotUrl => {
          if (
            timezone !== this.timezone ||
            (time == null && this.time != null) ||
            (time && time.some((t, iT) => t !== this.time[iT]))
          ) {
            return this.snapshotUrl
          }

          return snapshotUrl
        })
        .catch(error => {
          if (
            timezone !== this.timezone ||
            (time == null && this.time != null) ||
            (time && time.some((t, iT) => t !== this.time[iT]))
          ) {
            return
          }

          this.$store.dispatch('snackbar/showError', {
            content: error
          })

          return Promise.reject(error)
        })
        .finally(() => {
          if (
            timezone !== this.timezone ||
            (time == null && this.time != null) ||
            (time && time.some((t, iT) => t !== this.time[iT]))
          ) {
            return
          }

          this.isLoadingPreview = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
$height-xl-frame: 260px;
$height-mac13-frame: 240px;
$height-mac12-frame: 200px;

.panel-frame {
  height: $height-mac12-frame;

  @media screen and (min-width: 1281px) {
    height: $height-mac13-frame;
  }

  @media screen and (min-width: 1441px) {
    height: $height-xl-frame;
  }

  &.hide {
    height: 48px;
  }
}

.panel-data-editor {
  height: calc(100% - #{$height-mac12-frame} - 54px);

  @media screen and (min-width: 1281px) {
    height: calc(100% - #{$height-mac13-frame} - 54px);
  }

  @media screen and (min-width: 1441px) {
    height: calc(100% - #{$height-xl-frame} - 54px);
  }
}

.action-btn.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  font-size: 1rem;
}

.v-list.edit-model-list {
  .v-list-item {
    .v-list-item__icon {
      margin-right: 0;
    }
  }
}
</style>
